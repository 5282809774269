import React, { useState } from 'react';
import { useOuterClick } from '../../../hooks';
import {
  DropDownContainer,
  DropDownHeader,
  DropDownList,
  DropDownListContainer,
  FormErrorText,
  ListItem,
  HR,
} from './styled';
import SelectArrow from '../../Icons/SelectArrow/SelectArrow';

const Selector = ({
  options,
  placeholder,

  valueSelected,
  onSelectAction,
  error,
}) => {
  const dropdownMenuRef = useOuterClick((ev) => {
    if (isOpen) {
      setIsOpen(false);
    }
  });
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setIsOpen(false);
  };

  return (
    <DropDownContainer ref={dropdownMenuRef}>
      <DropDownHeader
        valueSelected={placeholder}
        isOpen={isOpen}
        onClick={toggling}
      >
        <span>{placeholder}</span>
        <SelectArrow isOpen={isOpen} />
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {Object.keys(options).map((option, index) => (
              <a
                href={options[option].link}
                key={index}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onOptionClicked(option)}
              >
                <ListItem
                  color={options[option].color}
                  style={
                    index === Object.keys(options).length - 1
                      ? { padding: '5px 10px 5px 10px' }
                      : null
                  }
                >
                  {options[option].label}
                  {index !== Object.keys(options).length - 1 && <HR />}
                </ListItem>
              </a>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}

      {error ? <FormErrorText>{error}</FormErrorText> : null}
    </DropDownContainer>
  );
};

export default Selector;
