import React from 'react';
import * as UI from '../index';
import { gtag } from '../../lib/utils';

const GtagButton = ({ children, onClick, eventName, ...props }) => {
  return (
    <UI.Button
      onClick={() => {
        gtag('event', eventName, { event_name: eventName });
        onClick && onClick();
      }}
      {...props}
    >
      {children}
    </UI.Button>
  );
};

export default GtagButton;
