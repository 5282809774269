import styled from 'styled-components';
import { COLORS } from '../../../models/colors';

export const DropDownContainer = styled('div')`
  width: fit-content;
  position: relative;
  z-index: 1;
  width: 165px;
`;

export const DropDownHeader = styled('div')`
  width: 100%;
  border-radius: 5px;
  border: 1px solid
    ${({ isOpen }) => (isOpen ? COLORS.PURPLE_DARK : COLORS.PURPLE_BRIGHT)};
  background: ${({ isOpen }) =>
    isOpen ? COLORS.PURPLE_DARK : COLORS.PURPLE_BRIGHT};
  padding: 11px 15px 9px 15px;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  gap: 10px;

  span {
    color: ${COLORS.RICH_BLACK};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.32px;
    text-transform: uppercase;
  }
  &:hover {
    cursor: pointer;
  }
  svg {
    transition: all 0.3s ease-in-out;
    path {
      stroke: ${({ isOpen }) =>
        isOpen ? COLORS.RICH_BLACK : COLORS.RICH_BLACK};
    }
  }
`;

export const DropDownListContainer = styled('div')`
  margin-top: 10px;
  position: absolute;
  width: 100%;
  a {
    text-decoration: none;
  }
`;

export const DropDownList = styled('ul')`
  margin: 0;
  background: rgba(28, 16, 47, 1);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* max-height: 213px;
  overflow-y: scroll; */
  border: 1px solid ${COLORS.PURPLE_BRIGHT};
  user-select: none;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid ${COLORS.PURPLE_GRAY};
    background-color: ${COLORS.PURPLE_GRAY};
    border-radius: 16px;
  }
`;

export const ListItem = styled('li')`
  list-style: none;
  color: ${({ color }) => color};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  padding: 5px 10px 0 10px;

  &:hover {
    cursor: pointer;
  }
`;

export const FormErrorText = styled.div`
  background-color: ${COLORS.WARNINGS};
  border-radius: 5px;
  margin: 0 20px;
  padding: 5px;
  position: absolute;
  bottom: -11px;
  left: -3px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 0.39px;
  color: ${COLORS.BLACK};
`;

export const HR = styled.div`
  height: 1px;
  background-color: rgba(98, 92, 113, 0.4);
  margin: 5px 0;
`;
