import React, { useState, useEffect } from 'react';

import { COLORS } from '../../models/colors';
import * as Styled from './styled';
import Selector from './components/Selector';

const SelectorApps = () => {
  const routes = [
    { link: 'https://app.tymio.com/', label: 'Yield', color: COLORS.PINK },
    {
      link: 'https://app.tymio.com/protect/',
      label: 'Protect',
      color: '#FFD581',
    },
    {
      link: 'https://t.me/tymioBot/protect',
      label: 'PROTECT (TON)',
      color: COLORS.LIGHT,
    },
  ];

  return (
    <Styled.SelectorApps>
      <Selector options={routes} placeholder={'go to apps'} />
    </Styled.SelectorApps>
  );
};

export default SelectorApps;
